import Vue from 'vue'
import VueRouter from 'vue-router'
// import Page from '../views/Page.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // [vue-router] Named Route 'page' has a default child route. When navigating 
    // to this named route (:to="{name: 'page'"), the default child route will not be 
    // rendered. Remove the name from this route and use the name of the default child 
    // route for named links instead.
    //
    // name: 'page',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page.vue'),
    children: [
      // {
      //   path: '',
      //   name: 'home',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      // },
      {
        path: '/send',
        name: 'send',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Send3.vue')
      },
      {
        path: '/send-excel',
        name: 'send-excel',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Send3_Excel.vue')
      },
      {
        path: '',
        name: 'logs',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Logs.vue')
      },
      {
        path: '/balance',
        name: 'balance',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Balance.vue')
      },
      {
        path: '/settings',
        name: 'settings',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Settings.vue')
      },
      {
        path: '/logout',
        name: 'logout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/Balance.vue')
      }
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('jwt');

  // trying to access a restricted page + not logged in
  // redirect to login page

  if (authRequired && !loggedIn) {
  // if (!loggedIn) {
    next({
      path: '/login',
      query: { nextUrl: to.fullPath }
    })
  } else {
    next();
  }
});

export default router
