import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count: 0
  },
  mutations: {
    increment (state) {
      state.count++
    }
  },
  actions: {
  },
  modules: {
  }
})


// this.$store.commit('increment')



// console.log(this.$store.state.count)



// computed: {
//   count () {
//     return this.$store.state.count
//   }



// computed: mapState([
//   // map this.count to store.state.count
//   'count'
// ])



// Note that mapState returns an object. How do we use it in combination with other local computed properties?
// Object Spread Operator

// computed: {
//   localComputed () { /* ... */ },
//   // mix this into the outer object with the object spread operator
//   ...mapState({
//     // ...
//   })
// }



