import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css'
import router from './router'
import store from './store'
import Axios from 'axios'



// Axios.interceptors.request.use((config) => {
//   console.log('request:', config)
//   return config
// }, (error) => {
//   console.log('woops something went wrong during request')
//   console.log(error)
  
//   return Promise.reject(error)
// }
// )

Axios.interceptors.response.use((response) => {
  // console.log('response:', response)
  return response
}, (error) => {
  const res = error.response
  // // console.log(error.response.status, error.response.statusText, error.response.data.detail)
  
  if (res.status==401 && res.statusText=='Unauthorized') {
    // error.response.status, error.response.statusText, error.response.data.detail
    // 401, Unauthorized, Signature has expired.
    localStorage.removeItem('jwt')
    localStorage.removeItem('user_fullname')
    localStorage.removeItem('account')
    localStorage.removeItem('upload')

    router.push('/login')

    return Promise.reject(error)
  }

  return res
  
}
)

Vue.config.productionTip = false

Vue.use(Antd)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
